<template>
  <div v-show="!isHidden" :style="computedStyle">
    <el-form-item
      :prop="name"
      :label="label"
      :class="elFormCssClasses"
      :style="elFormCss"
      :rules="[
        { required: _isRequired, message: $locale.main.message.required_field, trigger: 'blur' },
        rules.controlValues,
        rules.controlNotificValues
      ]"
    >
      <template slot="label">
        <span :class="labelCssClasses" :style="labelCss">
          {{ label }}
        </span>
        <el-tooltip v-if="tooltip" class="item" effect="dark" :content="tooltip">
          <i class="el-icon-question"></i>
        </el-tooltip>
      </template>

      <el-input
        :value="value"
        type="number"
        :name="name"
        :size="size"
        :placeholder="placeholder || $locale.main.placeholder.integer_field"
        :readonly="_isReadonly"
        @input="$emit('input', ($event || null))"
      ></el-input>
    </el-form-item>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import registryMixin from '../registry/registry_mixins'
import controlValues from '../control_values_mixin'
import InputLabel from '@/mixins/inputLabel.js'
import controlNotification from '../control_notification_mixin.js'

export default {
  name: 'a-integer',
  inject: {
    forceUpdateSettingsPanel: {
      default: () => () => {}
    }
  },
  mixins: [mixin, registryMixin, controlValues, controlNotification, InputLabel],
  props: {
    editorAlias: {
      type: String,
      description: 'alias'
    },
    label: {
      type: String,
      description: 'name'
    },
    name: {
      type: String,
      description: 'attribute',
      options: {
        removeSpaces: true
      }
    },
    placeholder: {
      type: String,
      description: 'placeholder'
    },
    tooltip: {
      type: String,
      description: 'tooltip'
    },
    controlValues: {
      type: Object,
      description: 'control_of_values',
      editor: 'ControlValues',
      default: () => {
        return {
          operator: null,
          type: 'integer',
          conditions: []
        }
      },
      options: { type: 'integer' }
    },
    controlNotificValues: {
      type: Object,
      description: 'control_notific_values',
      editor: 'ControlNotificationValues',
      default: () => {
        return {
          operator: null,
          type: 'integer',
          conditions: []
        }
      },
      options: { type: 'integer' }
    }
  },
  computed: {
    computedStyle () {
      let css = this.CSS
      if (this.align) {
        css += ';text-align:' + this.align
      }
      if (this.margin) {
        css += ';margin:' + this.margin
      }
      if (this.width && !this.block) {
        css += ';width:' + this.width
      }
      if (!this.block) {
        css += `;display: inline-block; width:${this.width || '200px'}`
      }
      if (this.wrapper) {
        css += ';display: block;'
      }

      return css
    }
  },
  watch: {
    editorAlias () {
      this.forceUpdateSettingsPanel()
    }
  }
}
</script>

<style scoped>

</style>
